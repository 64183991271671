import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import MultiSelectDropdown from "components/MultiSelectDropdown/MultiSelectDropdown";
import PublicIcon from "@mui/icons-material/Public";
import AlarmIcon from "@mui/icons-material/Alarm";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import AutocompleteField from "components/form-fields/AutocompleteField";
export const initialValues = {
  applyNonAggClicked: false,
  fileName: "",
  splitDownloadFile: "",
  savedName: "",
  primary: [
    { label: `Sales in Eaches (millions)`, value: "Sales in Eaches" },
    {
      label: `Falcon Forecast in Eaches (millions)`,
      value: "Falcon_Forecast",
    },
    {
      label: `HUL Forecast in Eaches (millions)`,
      value: "HUL_Forecast",
    },
  ],
  secondary: [],
  feature_fileName: [],
  variable_type: "Final Feature Importance Description",
  feature_importance_filters: {
    tdp: [],
    channel: [],
    cluster_name: [],
    basepack: [],
    basepackDescription: [],
    anaplanCustomer: [],
  },
  firstClickVariable: "",
  secondClickVariable: "",
  displayData: "Agg",
  filters: {
    BP: [],
    BP_Description: [],
    Modified_Brand: [],
    Pack_Cluster: [],
    Brand_Variant: [],
    UOM: [],
    Segment: [],
    Brand: [],
    Holiday: [],
    Cluster: [],
    Cluster_Code: [],
    Branch: [],
    Channel: [],
    Year: [],
    Year_BM: [],
    Sales_Category: [],
    Sales_Business_Group: [],
    Category: [],
    Sub_Category: [],
    Sales_Business: [],
    Format1: [],
    Format2: [],
    Seasonal_Tagging: [],
    Active_Delisted: [],
    Price_point_Tagging: [],
    Scope_Flag: [],
    Top_Packs: [],
    COTC_MD_Cell: [],
    Promo_Description: [],
    Modi_Acc_for_Discussions: [],
    Anaplan_Customer: [],
    Sales_Mode: [],
    Exculded: [],
    SM_Segment: [],
    Pack_Format: [],
    Animal_Farm: [],
    SM_Portfolio_Tag: [],
    SM_Bucketing: [],
    ABC_classification: [],
    PBBU: [],
    High_lead_time_capacity_constraint_item: [],
    ECOM_TAG: [],
    Segmentation: [],
    Accenture_Forecast: [],
    Max_Give_Amount: [],
    Max_Give_Percentage: [],
    Consumer_Promo: [],
    Promo_value: [],
    "GSV.rate": [],
  },
  units: "RPM",
  openDrawer: true,
  clickApply: false,
  timePeriod: "",

  decimal: 0,
  demandForecastUploadedFile: [],
  featuresFilter: [],
  tdpList: [],
};

export const mt_filter_initial = {
  BP: [],
  BP_Description: [],
  Modified_Brand: [],
  Brand_Variant: [],
  UOM: [],
  Segment: [],
  Brand: [],
  Top_Packs: [],
  COTC_MD_cell: [],
  Price_point_Tagging: [],
  Promotion_Description: [],
  Anaplan_Customer: [],
  Modi_Acc_for_Discussions: [],

  Channel: [],
  Sales_Mode: [],
  Year: [],
  Sales_Category: [],
  Category: [],
  Sales_Business: [],
  Sales_Business_Group: [],
  Format1: [],
  Format2: [],
  Seasonal_Tagging: [],
  Scope_Flag: [],
  Active_Delisted: [],
  Excluded: [],
};

export const gt_filter_initial = {
  // shruti_gt: [],
  BP: [],
  BP_Description: [],
  Modified_Brand: [],
  Pack_Cluster: [],
  Brand_Variant: [],
  UOM: [],
  Segment: [],
  // Segmentaion: [],
  Brand: [],
  Holiday: [],
  Cluster: [],
  Cluster_Code: [],
  Branch: [],
  Channel: [],
  Year: [],
  Year_BM: [],
  Sales_Category: [],
  Sales_Business_Group: [],
  Category: [],
  Sub_Category: [],
  Sales_Business: [],

  Format1: [],
  Format2: [],
  Seasonal_Tagging: [],
  Active_Delisted: [],
  Price_point_Tagging: [],
  Scope_Flag: [],
  Top_Packs: [],
  COTC_MD_Cell: [],
  Promo_Description: [],
  Modi_Acc_for_Discussions: [],
  Anaplan_Customer: [],
  Sales_Mode: [],
  Exculded: [],
  SM_Segment: [],
  Pack_Format: [],
  SM_Portfolio_Tag: [],
  SM_Bucketing: [],
  ABC_classification: [],
  PBBU: [],
  High_lead_time_capacity_constraint_item: [],
  ECOM_TAG: [],
  Accenture_Forecast: [],
  Max_Give_Amount: [],
  Max_Give_Percentage: [],
  Consumer_Promo: [],
  Promo_value: [],
  "GSV.rate": [],
};
export const gt_filter_initial_segemntation = {
  BP: [],
  BP_Description: [],
  Modified_Brand: [],
  Pack_Cluster: [],
  Brand_Variant: [],
  UOM: [],
  Segment: [],
  Segmentaion: [],
  Brand: [],
  Holiday: [],
  Cluster: [],
  Cluster_Code: [],
  Branch: [],
  Channel: [],
  Year: [],
  Year_BM: [],
  Sales_Category: [],
  Sales_Business_Group: [],
  Category: [],
  Sub_Category: [],
  Sales_Business: [],
  Format1: [],
  Format2: [],
  Seasonal_Tagging: [],
  Active_Delisted: [],
  Price_point_Tagging: [],
  Scope_Flag: [],
  Top_Packs: [],
  COTC_MD_Cell: [],
  Promo_Description: [],
  Modi_Acc_for_Discussions: [],
  Anaplan_Customer: [],
  Sales_Mode: [],
  Exculded: [],
  SM_Segment: [],
  Pack_Format: [],
  SM_Portfolio_Tag: [],
  SM_Bucketing: [],
  ABC_classification: [],
  PBBU: [],
  High_lead_time_capacity_constraint_item: [],
  ECOM_TAG: [],
  Accenture_Forecast: [],
  Max_Give_Amount: [],
  Max_Give_Percentage: [],
  Consumer_Promo: [],
  Promo_value: [],
  "GSV.rate": [],
};
export const gt_filter_initial_Segmentation = {
  BP: [],
  BP_Description: [],
  Modified_Brand: [],
  Pack_Cluster: [],
  Brand_Variant: [],
  UOM: [],
  Segment: [],
  Segmentation: [],
  Brand: [],
  Holiday: [],
  Cluster: [],
  Cluster_Code: [],
  Branch: [],
  Channel: [],
  Year: [],
  Year_BM: [],
  Sales_Category: [],
  Sales_Business_Group: [],
  Category: [],
  Sub_Category: [],
  Sales_Business: [],
  Format1: [],
  Format2: [],
  Seasonal_Tagging: [],
  Active_Delisted: [],
  Price_point_Tagging: [],
  Scope_Flag: [],
  Top_Packs: [],
  COTC_MD_Cell: [],
  Promo_Description: [],
  Modi_Acc_for_Discussions: [],
  Anaplan_Customer: [],
  Sales_Mode: [],
  Exculded: [],
  SM_Segment: [],
  Pack_Format: [],
  SM_Portfolio_Tag: [],
  SM_Bucketing: [],
  ABC_classification: [],
  PBBU: [],
  High_lead_time_capacity_constraint_item: [],
  ECOM_TAG: [],
  Accenture_Forecast: [],
  Max_Give_Amount: [],
  Max_Give_Percentage: [],
  Consumer_Promo: [],
  Promo_value: [],
  "GSV.rate": [],
};

// export const ecom_sm = {
//   BP: [],
//   Anaplan_Customer: [],
//   Modi_Acc_for_Discussions: [],
//   Cluster_Code: [],
//   Cluster: [],
//   Channel: [],
//   Sales_Mode: [],
//   Book_Month: [],
//   QTR_BM: [],
//   Year_BM: [],
//   Sales_Category: [],
//   Category: [],
//   Sales_Business: [],
//   Sales_Business_Group: [],
//   UOM: [],
//   Pack_Cluster: [],
//   Segment: [],
//   Brand: [],
//   Seasonal_Tagging: [],
//   Scope_Flag: [],
//   Modified_Brand: [],
//   Format1: [],
//   Format2: [],
//   Active_Delisted: [],
//   COTC_MD_Cell: [],
//   Price_point_Tagging: [],
//   BP_Description: [],
//   ABC_classification: [],
// SM_Segment: [],
// };
export const ecom = {
  BP: [],
  BP_Description: [],
  Modified_Brand: [],
  Pack_Cluster: [],
  Brand_Variant: [],
  UOM: [],
  Segment: [],
  Segmentation: [],
  Brand: [],
  Holiday: [],
  Cluster: [],
  Cluster_Code: [],
  Branch: [],
  Channel: [],
  Year: [],
  Year_BM: [],
  Sales_Category: [],
  Sales_Business_Group: [],
  Category: [],
  Sub_Category: [],
  Sales_Business: [],
  Format1: [],
  Format2: [],
  Seasonal_Tagging: [],
  Active_Delisted: [],
  Price_point_Tagging: [],
  Scope_Flag: [],
  Top_Packs: [],
  COTC_MD_Cell: [],
  Promo_Description: [],
  Modi_Acc_for_Discussions: [],
  Anaplan_Customer: [],
  Sales_Mode: [],
  Exculded: [],
  // SM_Segment: [],
  Pack_Format: [],
  SM_Portfolio_Tag: [],
  // SM_Bucketing: [],
  ABC_classification: [],
  PBBU: [],
  High_lead_time_capacity_constraint_item: [],
  ECOM_TAG: [],
  // Accenture_Forecast: [],
  // Max_Give_Amount: [],
  // Max_Give_Percentage: [],
  // Consumer_Promo: [],
  // Promo_value: [],
  // "GSV.rate": [],
};
export const gt_filter = [
  {
    title: "Product",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "Base Pack",
        name: "BP",
        component: MultiSelectDropdown,
      },
      {
        label: "Description",
        name: "BP_Description",
        component: MultiSelectDropdown,
      },
      {
        label: "Modified Brand",
        name: "Modified_Brand",
        component: MultiSelectDropdown,
      },
      {
        label: "Brand Variant",
        name: "Brand_Variant",
        component: MultiSelectDropdown,
      },
      {
        label: "Pack Cluster",
        name: "Pack_Cluster",
        component: MultiSelectDropdown,
      },
      {
        label: "UOM",
        name: "UOM",
        component: MultiSelectDropdown,
      },
      {
        label: "Segment",
        name: "Segment",
        component: MultiSelectDropdown,
      },
      // {
      //   label: "Segmentation",
      //   name: "Segmentation",
      //   component: MultiSelectDropdown,
      // },
      {
        label: "Brand",
        name: "Brand",
        component: MultiSelectDropdown,
      },
      {
        label: "Holiday",
        name: "Holiday",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Geography",
    icon: PublicIcon,
    filters: [
      {
        label: "Cluster",
        name: "Cluster",
        component: MultiSelectDropdown,
      },
      // {
      //   label: "Cluster Code",
      //   name: "Cluster_Code",
      //   component: MultiSelectDropdown,
      // },
      {
        label: "Branch",
        name: "Branch",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Channel",
    icon: LocalGroceryStoreIcon,
    filters: [
      {
        label: "Channel",
        name: "Channel",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Time",
    icon: AlarmIcon,
    filters: [
      {
        label: "Year",
        name: "Year",
        component: MultiSelectDropdown,
      },
      {
        label: "BM Year",
        name: "Year_BM",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Big C",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "Sales Category",
        name: "Sales_Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Category",
        name: "Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Sub Category",
        name: "Sub_Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Sales Business",
        name: "Sales_Business",
        component: MultiSelectDropdown,
      },
      {
        label: "Sales Business Group",
        name: "Sales_Business_Group",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Format",
    icon: PendingOutlinedIcon,
    filters: [
      {
        label: "Format 1",
        name: "Format1",
        component: MultiSelectDropdown,
      },
      {
        label: "Format 2",
        name: "Format2",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Tags",
    icon: LocalOfferIcon,
    filters: [
      {
        label: "Seasonal Tagging",
        name: "Seasonal_Tagging",
        component: MultiSelectDropdown,
      },
      {
        label: "Active/Delisted",
        name: "Active_Delisted",
        component: MultiSelectDropdown,
      },
      {
        label: "Price Point Tagging",
        name: "Price_point_Tagging",
        component: MultiSelectDropdown,
      },
      {
        label: "Scope Flag",
        name: "Scope_Flag",
        component: MultiSelectDropdown,
      },
    ],
  },
];
export const gt_filter_segmentation = [
  {
    title: "Product",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "Base Pack",
        name: "BP",
        component: MultiSelectDropdown,
      },
      {
        label: "Description",
        name: "BP_Description",
        component: MultiSelectDropdown,
      },
      {
        label: "Modified Brand",
        name: "Modified_Brand",
        component: MultiSelectDropdown,
      },
      {
        label: "Brand Variant",
        name: "Brand_Variant",
        component: MultiSelectDropdown,
      },
      {
        label: "Pack Cluster",
        name: "Pack_Cluster",
        component: MultiSelectDropdown,
      },
      {
        label: "UOM",
        name: "UOM",
        component: MultiSelectDropdown,
      },
      {
        label: "Segment",
        name: "Segment",
        component: MultiSelectDropdown,
      },
      {
        label: "Segmentation",
        name: "Segmentation",
        component: MultiSelectDropdown,
      },
      {
        label: "Brand",
        name: "Brand",
        component: MultiSelectDropdown,
      },
      {
        label: "Holiday",
        name: "Holiday",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Geography",
    icon: PublicIcon,
    filters: [
      {
        label: "Cluster",
        name: "Cluster",
        component: MultiSelectDropdown,
      },
      // {
      //   label: "Cluster Code",
      //   name: "Cluster_Code",
      //   component: MultiSelectDropdown,
      // },
      {
        label: "Branch",
        name: "Branch",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Channel",
    icon: LocalGroceryStoreIcon,
    filters: [
      {
        label: "Channel",
        name: "Channel",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Time",
    icon: AlarmIcon,
    filters: [
      {
        label: "Year",
        name: "Year",
        component: MultiSelectDropdown,
      },
      {
        label: "BM Year",
        name: "Year_BM",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Big C",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "Sales Category",
        name: "Sales_Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Category",
        name: "Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Sub Category",
        name: "Sub_Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Sales Business",
        name: "Sales_Business",
        component: MultiSelectDropdown,
      },
      {
        label: "Sales Business Group",
        name: "Sales_Business_Group",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Format",
    icon: PendingOutlinedIcon,
    filters: [
      {
        label: "Format 1",
        name: "Format1",
        component: MultiSelectDropdown,
      },
      {
        label: "Format 2",
        name: "Format2",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Tags",
    icon: LocalOfferIcon,
    filters: [
      {
        label: "Seasonal Tagging",
        name: "Seasonal_Tagging",
        component: MultiSelectDropdown,
      },
      {
        label: "Active/Delisted",
        name: "Active_Delisted",
        component: MultiSelectDropdown,
      },
      {
        label: "Price Point Tagging",
        name: "Price_point_Tagging",
        component: MultiSelectDropdown,
      },
      {
        label: "Scope Flag",
        name: "Scope_Flag",
        component: MultiSelectDropdown,
      },
    ],
  },
];

export const gt_filter_Segmentation = [
  {
    title: "Product",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "Base Pack",
        name: "BP",
        component: MultiSelectDropdown,
      },
      {
        label: "Description",
        name: "BP_Description",
        component: MultiSelectDropdown,
      },
      {
        label: "Modified Brand",
        name: "Modified_Brand",
        component: MultiSelectDropdown,
      },
      {
        label: "Brand Variant",
        name: "Brand_Variant",
        component: MultiSelectDropdown,
      },
      {
        label: "Pack Cluster",
        name: "Pack_Cluster",
        component: MultiSelectDropdown,
      },
      {
        label: "UOM",
        name: "UOM",
        component: MultiSelectDropdown,
      },
      {
        label: "Segment",
        name: "Segment",
        component: MultiSelectDropdown,
      },
      {
        label: "Segmentation",
        name: "Segmentation",
        component: MultiSelectDropdown,
      },
      {
        label: "Brand",
        name: "Brand",
        component: MultiSelectDropdown,
      },
      {
        label: "Holiday",
        name: "Holiday",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Geography",
    icon: PublicIcon,
    filters: [
      {
        label: "Cluster",
        name: "Cluster",
        component: MultiSelectDropdown,
      },
      // {
      //   label: "Cluster Code",
      //   name: "Cluster_Code",
      //   component: MultiSelectDropdown,
      // },
      {
        label: "Branch",
        name: "Branch",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Channel",
    icon: LocalGroceryStoreIcon,
    filters: [
      {
        label: "Channel",
        name: "Channel",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Time",
    icon: AlarmIcon,
    filters: [
      {
        label: "Year",
        name: "Year",
        component: MultiSelectDropdown,
      },
      {
        label: "BM Year",
        name: "Year_BM",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Big C",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "Sales Category",
        name: "Sales_Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Category",
        name: "Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Sub Category",
        name: "Sub_Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Sales Business",
        name: "Sales_Business",
        component: MultiSelectDropdown,
      },
      {
        label: "Sales Business Group",
        name: "Sales_Business_Group",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Format",
    icon: PendingOutlinedIcon,
    filters: [
      {
        label: "Format 1",
        name: "Format1",
        component: MultiSelectDropdown,
      },
      {
        label: "Format 2",
        name: "Format2",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Tags",
    icon: LocalOfferIcon,
    filters: [
      {
        label: "Seasonal Tagging",
        name: "Seasonal_Tagging",
        component: MultiSelectDropdown,
      },
      {
        label: "Active/Delisted",
        name: "Active_Delisted",
        component: MultiSelectDropdown,
      },
      {
        label: "Price Point Tagging",
        name: "Price_point_Tagging",
        component: MultiSelectDropdown,
      },
      {
        label: "Scope Flag",
        name: "Scope_Flag",
        component: MultiSelectDropdown,
      },
    ],
  },
];
export const mt_filter = [
  {
    title: "Product",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "BasePack",
        name: "BP",
        component: MultiSelectDropdown,
      },

      {
        label: "Description",
        name: "BP_Description",
        component: MultiSelectDropdown,
      },
      {
        label: "Modified Brand",
        name: "Modified_Brand",
        component: MultiSelectDropdown,
      },
      {
        label: "Brand Variant",
        name: "Brand_Variant",
        component: MultiSelectDropdown,
      },
      // {
      //   label: "Pack Cluster",
      //   name: "Pack_Cluster",
      //   component: MultiSelectDropdown,
      // },
      {
        label: "UOM",
        name: "UOM",
        component: MultiSelectDropdown,
      },
      {
        label: "Segment",
        name: "Segment",
        component: MultiSelectDropdown,
      },
      {
        label: "Brand",
        name: "Brand",
        component: MultiSelectDropdown,
      },
      {
        label: "Top Packs",
        name: "Top_Packs",
        component: MultiSelectDropdown,
      },
      {
        label: "COTC/MD Cell",
        name: "COTC_MD_cell",
        component: MultiSelectDropdown,
      },
      {
        label: "Price Point Tagging",
        name: "Price_point_Tagging",
        component: MultiSelectDropdown,
      },
      {
        label: "Promo Description",
        name: "Promotion_Description",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Customer",
    icon: AccountCircleRoundedIcon,
    filters: [
      {
        label: "APO Customer",
        name: "Anaplan_Customer",
        component: MultiSelectDropdown,
      },
      {
        label: "Modified Customer",
        name: "Modi_Acc_for_Discussions",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Geography",
    icon: PublicIcon,
    filters: [
      {
        label: "Cluster",
        name: "Cluster",
        component: MultiSelectDropdown,
      },
      {
        label: "Branch",
        name: "Branch",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Channel",
    icon: LocalGroceryStoreIcon,
    filters: [
      {
        label: "Channel",
        name: "Channel",
        component: MultiSelectDropdown,
      },
      {
        label: "Sales Mode",
        name: "Sales_Mode",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Time",
    icon: AlarmIcon,
    filters: [
      {
        label: "Year",
        name: "Year",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Big C",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "Sales Category",
        name: "Sales_Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Category",
        name: "Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Sales Business",
        name: "Sales_Business",
        component: MultiSelectDropdown,
      },
      {
        label: "Sales Business Group",
        name: "Sales_Business_Group",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Format",
    icon: PendingOutlinedIcon,
    filters: [
      {
        label: "Format 1",
        name: "Format1",
        component: MultiSelectDropdown,
      },
      {
        label: "Format 2",
        name: "Format2",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Tags",
    icon: LocalOfferIcon,
    filters: [
      {
        label: "Seasonal Tagging",
        name: "Seasonal_Tagging",
        component: MultiSelectDropdown,
      },
      {
        label: "Scope Flag",
        name: "Scope_Flag",
        component: MultiSelectDropdown,
      },
      {
        label: "Active/Delisted",
        name: "Active_Delisted",
        component: MultiSelectDropdown,
      },
      {
        label: "Excluded",
        name: "Excluded",
        component: MultiSelectDropdown,
      },
    ],
  },
];

export const ecom_filter = [
  {
    title: "Product",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "BasePack",
        name: "BP",
        component: MultiSelectDropdown,
      },
      {
        label: "Description",
        name: "BP_Description",
        component: MultiSelectDropdown,
      },
      {
        label: "Modified Brand",
        name: "Modified Brand",
        component: MultiSelectDropdown,
      },

      {
        label: "UOM",
        name: "UOM",
        component: MultiSelectDropdown,
      },

      // {
      //   label: "Pack Format",
      //   name: "Pack_Format",
      //   component: MultiSelectDropdown,
      // },
      {
        label: "Segment",
        name: "Segment",
        component: MultiSelectDropdown,
      },
      {
        label: "Brand",
        name: "Brand",
        component: MultiSelectDropdown,
      },
      // {
      //   label: "Price Point Tagging",
      //   name: "Price point Tagging        ",
      //   component: MultiSelectDropdown,
      // },
      // {
      //   label: "Segment",
      //   name: "Segment",
      //   component: MultiSelectDropdown,
      // },
      {
        label: "Animal Farm",
        name: "Animal Farm",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Geography",
    icon: PublicIcon,
    filters: [
      // {
      //   label: "Anaplan_Customer",
      //   name: "Anaplan_Customer",
      //   component: MultiSelectDropdown,
      // },
      // {
      //   label: "Modified Customer",
      //   name: "Modi_Cust",
      //   component: MultiSelectDropdown,
      // },
      {
        label: "Cluster",
        name: "Cluster",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Channel",
    icon: LocalGroceryStoreIcon,
    filters: [
      {
        label: "Channel",
        name: "Channel",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Time",
    icon: AlarmIcon,
    filters: [
      {
        label: "BM Year",
        name: "Year_BM",
        component: MultiSelectDropdown,
      },
      { label: "Year", name: "Year", component: MultiSelectDropdown },
      {
        label: "MOC",
        name: "MOC",
        component: MultiSelectDropdown,
      },
      { label: "QTR", name: "QTR", component: MultiSelectDropdown },
      { label: "QTR_BM", name: "QTR_BM", component: MultiSelectDropdown },
    ],
  },
  {
    title: "Big C",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "Sales Category",
        name: "Sales_Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Category",
        name: "Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Sales Business",
        name: "Sales Business",
        component: MultiSelectDropdown,
      },
      {
        label: "Sales Business Group",
        name: "Sales Business Group",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Format",
    icon: PendingOutlinedIcon,
    filters: [
      {
        label: "Format 1",
        name: "Format1",
        component: MultiSelectDropdown,
      },
      {
        label: "Format 2",
        name: "Format2",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Tags",
    icon: LocalOfferIcon,
    filters: [
      {
        label: "Seasonal Tagging",
        name: "Seasonal_Tagging",
        component: MultiSelectDropdown,
      },
      {
        label: "Scope Flag",
        name: "Scope_Flag",
        component: MultiSelectDropdown,
      },
      {
        label: "Active/Delisted",
        name: "Active/Delisted",
        component: MultiSelectDropdown,
      },
      // {
      //   label: "SM Portfolio Tag",
      //   name: "SM_Portfolio_Tag",
      //   component: MultiSelectDropdown,
      // },
      // {
      //   label: "SM Bucketing",
      //   name: "SM_Bucketing",
      //   component: MultiSelectDropdown,
      // },
      // {
      //   label: "ABC classification",
      //   name: "ABC_classification",
      //   component: MultiSelectDropdown,
      // },
      // {
      //   label: "PBBU",
      //   name: "PBBU",
      //   component: MultiSelectDropdown,
      // },
      // {
      //   label: "High lead time",
      //   name: "High_lead_time_capacity_constraint_item",
      //   component: MultiSelectDropdown,
      // },
      // {
      //   label: "Ecom Tag",
      //   name: "ECOM_TAG",
      //   component: MultiSelectDropdown,
      // },
    ],
  },
];
export const ecom_filter_Depot = [
  {
    title: "Product",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "BasePack",
        name: "BP",
        component: MultiSelectDropdown,
      },
      {
        label: "Description",
        name: "BP_Description",
        component: MultiSelectDropdown,
      },
      {
        label: "Modified Brand",
        name: "Modified Brand",
        component: MultiSelectDropdown,
      },

      {
        label: "UOM",
        name: "UOM",
        component: MultiSelectDropdown,
      },

      // {
      //   label: "Pack Format",
      //   name: "Pack_Format",
      //   component: MultiSelectDropdown,
      // },
      {
        label: "Segment",
        name: "Segment",
        component: MultiSelectDropdown,
      },
      {
        label: "Brand",
        name: "Brand",
        component: MultiSelectDropdown,
      },
      // {
      //   label: "Price Point Tagging",
      //   name: "Price point Tagging        ",
      //   component: MultiSelectDropdown,
      // },
      // {
      //   label: "Segment",
      //   name: "Segment",
      //   component: MultiSelectDropdown,
      // },
      {
        label: "Animal Farm",
        name: "Animal Farm",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Geography",
    icon: PublicIcon,
    filters: [
      // {
      //   label: "Anaplan_Customer",
      //   name: "Anaplan_Customer",
      //   component: MultiSelectDropdown,
      // },
      // {
      //   label: "Modified Customer",
      //   name: "Modi_Cust",
      //   component: MultiSelectDropdown,
      // },
      {
        label: "Depot",
        name: "Depot_Transaction",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Channel",
    icon: LocalGroceryStoreIcon,
    filters: [
      {
        label: "Channel",
        name: "Channel",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Time",
    icon: AlarmIcon,
    filters: [
      {
        label: "BM Year",
        name: "Year_BM",
        component: MultiSelectDropdown,
      },
      { label: "Year", name: "Year", component: MultiSelectDropdown },
      {
        label: "MOC",
        name: "MOC",
        component: MultiSelectDropdown,
      },
      { label: "QTR", name: "QTR", component: MultiSelectDropdown },
      { label: "QTR_BM", name: "QTR_BM", component: MultiSelectDropdown },
    ],
  },
  {
    title: "Big C",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "Sales Category",
        name: "Sales_Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Category",
        name: "Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Sales Business",
        name: "Sales Business",
        component: MultiSelectDropdown,
      },
      {
        label: "Sales Business Group",
        name: "Sales Business Group",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Format",
    icon: PendingOutlinedIcon,
    filters: [
      {
        label: "Format 1",
        name: "Format1",
        component: MultiSelectDropdown,
      },
      {
        label: "Format 2",
        name: "Format2",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Tags",
    icon: LocalOfferIcon,
    filters: [
      {
        label: "Seasonal Tagging",
        name: "Seasonal_Tagging",
        component: MultiSelectDropdown,
      },
      {
        label: "Scope Flag",
        name: "Scope_Flag",
        component: MultiSelectDropdown,
      },
      {
        label: "Active/Delisted",
        name: "Active/Delisted",
        component: MultiSelectDropdown,
      },
      // {
      //   label: "SM Portfolio Tag",
      //   name: "SM_Portfolio_Tag",
      //   component: MultiSelectDropdown,
      // },
      // {
      //   label: "SM Bucketing",
      //   name: "SM_Bucketing",
      //   component: MultiSelectDropdown,
      // },
      // {
      //   label: "ABC classification",
      //   name: "ABC_classification",
      //   component: MultiSelectDropdown,
      // },
      // {
      //   label: "PBBU",
      //   name: "PBBU",
      //   component: MultiSelectDropdown,
      // },
      // {
      //   label: "High lead time",
      //   name: "High_lead_time_capacity_constraint_item",
      //   component: MultiSelectDropdown,
      // },
      // {
      //   label: "Ecom Tag",
      //   name: "ECOM_TAG",
      //   component: MultiSelectDropdown,
      // },
    ],
  },
];

export const ecom_sm_filter = [
  {
    title: "Product",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "BasePack",
        name: "BP",
        component: MultiSelectDropdown,
      },
      // {
      //   label: "Description",
      //   name: "BP_Description",
      //   component: MultiSelectDropdown,
      // },
      {
        label: "Modified Brand",
        name: "Modified_Brand",
        component: MultiSelectDropdown,
      },
      // {
      //   label: "Brand Variant",
      //   name: "Brand_Variant",
      //   component: MultiSelectDropdown,
      // },
      {
        label: "Pack Cluster",
        name: "Pack_Cluster",
        component: MultiSelectDropdown,
      },
      {
        label: "UOM",
        name: "UOM",
        component: MultiSelectDropdown,
      },
      {
        label: "Segment",
        name: "Segment",
        component: MultiSelectDropdown,
      },
      {
        label: "Brand",
        name: "Brand",
        component: MultiSelectDropdown,
      },
      // {
      //   label: "Top Packs",
      //   name: "Top_Packs",
      //   component: MultiSelectDropdown,
      // },
      {
        label: "COTC/MD Cell",
        name: "COTC_MD_cell",
        component: MultiSelectDropdown,
      },
      {
        label: "Price Point Tagging",
        name: "Price_point_Tagging",
        component: MultiSelectDropdown,
      },
      // {
      //   label: "Promo Description",
      //   name: "Promotion_Description",
      //   component: MultiSelectDropdown,
      // },
    ],
  },
  {
    title: "Customer",
    icon: AccountCircleRoundedIcon,
    filters: [
      {
        label: "APO Customer",
        name: "Anaplan_Customer",
        component: MultiSelectDropdown,
      },
      {
        label: "Modified Customer",
        name: "Modi_Acc_for_Discussions",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Geography",
    icon: PublicIcon,
    filters: [
      {
        label: "Cluster",
        name: "Cluster",
        component: MultiSelectDropdown,
      },
      {
        label: "Cluster_Code",
        name: "Cluster_Code",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Channel",
    icon: LocalGroceryStoreIcon,
    filters: [
      {
        label: "Channel",
        name: "Channel",
        component: MultiSelectDropdown,
      },
      {
        label: "Sales Mode",
        name: "Sales_Mode",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Time",
    icon: AlarmIcon,
    filters: [
      {
        label: "Year",
        name: "Year_BM",
        component: MultiSelectDropdown,
      },
      {
        label: "QTR_BM",
        name: "QTR_BM",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Big C",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "Sales Category",
        name: "Sales_Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Category",
        name: "Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Sales Business",
        name: "Sales_Business",
        component: MultiSelectDropdown,
      },
      {
        label: "Sales Business Group",
        name: "Sales_Business_Group",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Format",
    icon: PendingOutlinedIcon,
    filters: [
      {
        label: "Format 1",
        name: "Format1",
        component: MultiSelectDropdown,
      },
      {
        label: "Format 2",
        name: "Format2",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Tags",
    icon: LocalOfferIcon,
    filters: [
      {
        label: "Seasonal Tagging",
        name: "Seasonal_Tagging",
        component: MultiSelectDropdown,
      },
      {
        label: "Scope Flag",
        name: "Scope_Flag",
        component: MultiSelectDropdown,
      },
      {
        label: "Active/Delisted",
        name: "Active_Delisted",
        component: MultiSelectDropdown,
      },
      // {
      //   label: "Excluded",
      //   name: "Excluded",
      //   component: MultiSelectDropdown,
      // },
    ],
  },
];
export const feature_importance_filters = {
  title: "Feature Importance",
  icon: LocalOfferIcon,
  filters: [
    {
      label: "Channel",
      name: "feature_importance_filters.channel",
      component: AutocompleteField,
    },
    {
      label: "Cluster",
      name: "feature_importance_filters.cluster_name",
      component: AutocompleteField,
    },
    {
      label: "Basepack",
      name: "feature_importance_filters.basepack",
      component: AutocompleteField,
    },
    {
      label: "TDP",
      name: "feature_importance_filters.tdp",
      component: AutocompleteField,
    },
  ],
};

export const colDef = [
  {
    headerName: "BP Description",
    field: "BP_Description",
    minWidth: 150,
    headerTooltip: "BP Description",
  },
  {
    headerName: "Cluster",
    field: "Cluster Name",
    minWidth: 150,
    headerTooltip: "Cluster",
  },
  {
    headerName: "TDP",
    field: "TDP",
    minWidth: 150,
    headerTooltip: "TDP",
    cellStyle: () => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }),
  },
  {
    headerName: "Value",
    field: "feature_importance",
    minWidth: 150,
    headerTooltip: "Value",
    cellStyle: () => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }),
  },
];

export const ecom_filter_2 = [
  {
    title: "Product",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "BasePack",
        name: "BP",
        component: MultiSelectDropdown,
      },
      {
        label: "Description",
        name: "BP_Description",
        component: MultiSelectDropdown,
      },
      {
        label: "Modified Brand",
        name: "Modified_Brand",
        component: MultiSelectDropdown,
      },
      {
        label: "Pack Cluster",
        name: "Pack_Cluster",
        component: MultiSelectDropdown,
      },
      {
        label: "Brand Variant",
        name: "Brand_Variant",
        component: MultiSelectDropdown,
      },
      {
        label: "UOM",
        name: "UOM",
        component: MultiSelectDropdown,
      },
      {
        label: "Segment",
        name: "Segment",
        component: MultiSelectDropdown,
      },
      {
        label: "Brand",
        name: "Brand",
        component: MultiSelectDropdown,
      },
      {
        label: "Segmentation",
        name: "Segmentation",
        component: MultiSelectDropdown,
      },
      {
        label: "Top Packs",
        name: "Top_Packs",
        component: MultiSelectDropdown,
      },
      {
        label: "COTC/MD Cell",
        name: "COTC_MD_cell",
        component: MultiSelectDropdown,
      },
      {
        label: "Price Point Tagging",
        name: "Price_point_Tagging",
        component: MultiSelectDropdown,
      },
      {
        label: "Promo Description",
        name: "Promotion_Description",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Customer",
    icon: AccountCircleRoundedIcon,
    filters: [
      {
        label: "APO Customer",
        name: "Anaplan_Customer",
        component: MultiSelectDropdown,
      },
      {
        label: "Modified Customer",
        name: "Modi_Acc_for_Discussions",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Geography",
    icon: PublicIcon,
    filters: [
      {
        label: "Cluster",
        name: "Cluster",
        component: MultiSelectDropdown,
      },
      {
        label: "Cluster_Code",
        name: "Cluster_Code",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Channel",
    icon: LocalGroceryStoreIcon,
    filters: [
      {
        label: "Channel",
        name: "Channel",
        component: MultiSelectDropdown,
      },
      {
        label: "Sales Mode",
        name: "Sales_Mode",
        component: MultiSelectDropdown,
      },
      {
        label: "Branch",
        name: "Branch",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Time",
    icon: AlarmIcon,
    filters: [
      {
        label: "Year",
        name: "Year_BM",
        component: MultiSelectDropdown,
      },
      {
        label: "Year",
        name: "Year",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Big C",
    icon: CategoryOutlinedIcon,
    filters: [
      {
        label: "Sales Category",
        name: "Sales_Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Category",
        name: "Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Sub Category",
        name: "Sub_Category",
        component: MultiSelectDropdown,
      },
      {
        label: "Sales Business",
        name: "Sales_Business",
        component: MultiSelectDropdown,
      },
      {
        label: "Sales Business Group",
        name: "Sales_Business_Group",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Format",
    icon: PendingOutlinedIcon,
    filters: [
      {
        label: "Format 1",
        name: "Format1",
        component: MultiSelectDropdown,
      },
      {
        label: "Format 2",
        name: "Format2",
        component: MultiSelectDropdown,
      },
    ],
  },
  {
    title: "Tags",
    icon: LocalOfferIcon,
    filters: [
      {
        label: "Seasonal Tagging",
        name: "Seasonal_Tagging",
        component: MultiSelectDropdown,
      },
      {
        label: "Scope Flag",
        name: "Scope_Flag",
        component: MultiSelectDropdown,
      },
      {
        label: "Active/Delisted",
        name: "Active_Delisted",
        component: MultiSelectDropdown,
      },
      {
        label: "Excluded",
        name: "Excluded",
        component: MultiSelectDropdown,
      },
      {
        label: "Holiday",
        name: "Holiday",
        component: MultiSelectDropdown,
      },
      // {
      //   label: "Holiday",
      //   name: "Holiday",
      //   component: MultiSelectDropdown,
      // },
      // {
      //   label: "Holiday",
      //   name: "Holiday",
      //   component: MultiSelectDropdown,
      // },
    ],
  },
];
