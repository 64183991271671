import { useMutation } from "react-query";
import { postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetBPList(fileName, cluster) {
  const path = "demand_forecast/get_bp_filters";

  const {
    data = [],
    mutate,
    isLoading,
    error,
    isError,
  } = useMutation((payload) =>
    postApi(path, {
      ...payload,
    })
  );

  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [error?.response?.data, isError, showError]);

  const { BP_description = [] } = data ?? [];

  return {
    bpOptions: BP_description,
    bpOptionsLoading: isLoading,
    bpMutate: mutate,
  };
}
