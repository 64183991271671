import { Grid, Box, Button, Typography, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Section from "components/Section";
import React from "react";
import ReactECharts from "echarts-for-react";
import useDemandForecast from "./hooks/useDemandForecast";
import ButtonGroup from "components/ButtonGroup";
import ExportOption from "components/ExportOption/ExportOption";
import DisplayButton from "components/DisplayButton/DisplayButton";
import { Field, useFormikContext } from "formik";
import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { columns } from "./mappings";
import { StyledCard } from "components/Section/Section.styles";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axisOptions } from "./dropdownOption";
import FeatureImportance from "./components/FeatureImportance";
import DataGridTable from "components/DataGridTable/DataGridTable";
import { useDeepCompareEffect } from "utils/useDeepCompareEffect";
import Loader from "components/Loader";
import AutocompleteField from "components/form-fields/AutocompleteField";
import { useQueryClient } from "react-query";
import { useTheme } from "@emotion/react";
import axios from "axios";
import Features from "./components/Features";
import OptionsButton from "components/OptionsButton/OptionsButton";
import useGetDownloadFileName from "services/DemandForecast/useGetDownloadFileName";
import useDemandForecastNonAgg from "./hooks/useDemandForecastNonAgg";
// import TestComponent from "views/Test/components/TestComponent";


export default function DemandForecast({ enableAPIs }) {
  const { values, setFieldValue } = useFormikContext();
  const [checked, setChecked] = useState("chart");
  const options = axisOptions(values.fileName.split("_")[0]);
 
  const [chartInstance, setchartInstance] = useState(null);
  // const [decimals, setDecimals] = useState(0);
  const { downloadData } = useGetDownloadFileName();
  const splitFileNames = ["GT_Haircare", "GT_Skin_Non_Winter"];
  // const [displayData, setDisplayData] = useState("Agg");

  const shouldDisplayFormControl = (values.filters['BP'].length > 0 || values.filters['BP_Description'].length > 0) && (values.applyNonAggClicked || values.displayData === "Non-Agg");
  const { data, accuracyOption, demandForecastLoading, onApply } =
    useDemandForecast(
      values,
      values.timePeriod,
      values.primary,
      values.secondary,
      enableAPIs,
      values.decimal
    );

    const { dataNonAgg, accuracyOptionNonAgg, demandForecastLoadingNonAgg,onApplyNonAgg } =
    useDemandForecastNonAgg(
      values,
      values.timePeriod,
      values.primary,
      values.secondary,
      enableAPIs,
      values.decimal,
      values.displayData
    );
  
  const [showLoader, setShowLoader] = useState();

  function getTDP(date) {
    const baseDate = new Date('2024-01-21');
    const baseTDP = 202404;
    const dayDifference = Math.floor((date - baseDate) / (1000 * 60 * 60 * 24));
    const tdpIncrements = Math.floor(dayDifference / 10);
    const calculatedTDP = baseTDP + tdpIncrements;
    return calculatedTDP;
}

let tdpForInputDate

const today = new Date();
const year = today.getFullYear();
const month = today.getMonth();
const dd = today.getDate();

if(dd >= 8 && dd <= 21){
  tdpForInputDate = getTDP(new Date(year, month, 8))-1;
}
if((dd >= 22 && dd <= 31)){
  tdpForInputDate = getTDP(new Date(year, month, 22))-1;
}
if((dd>=1 && dd<=7)){
  tdpForInputDate = getTDP(new Date(year, month-1, 22))-1;
}



  const columnDefs = columns(
    values.primary,
    values.secondary,
    values.timePeriod,
    values.decimal
  );

  const [gridApi, setGridApi] = useState(null);

  const exportCsv = () => {
    if (gridApi) {
      gridApi.exportDataAsCsv();
    }
  };

  useDeepCompareEffect(() => {
    if (values.displayData==="Agg" && chartInstance) {
      chartInstance.setOption(accuracyOption, {
        replaceMerge: ["xAxis", "yAxis", "series"],
      });
    }
  }, [accuracyOption, chartInstance]);

  useDeepCompareEffect(() => {
    if (values.displayData==="Non-Agg" && chartInstance) {
      chartInstance.setOption(accuracyOptionNonAgg, {
        replaceMerge: ["xAxis", "yAxis", "series"],
      });
    }
  }, [accuracyOptionNonAgg, chartInstance]);

  const saveasImage = () => {
    let uri = chartInstance.getDataURL({ backgroundColor: "#fff" });
    let link = document.createElement("a");
    link.download = "Demand_Forecast";
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    // setAnchorEl(null);
  };

  const handleChange = (event) => {
    setFieldValue("decimal", event.target.value);
  };

  const theme = useTheme();
  //blob url Dont remove

  const downloadFile = async () => {
    setShowLoader(true);
    let fileName;
  
    // Determine the file name based on the conditions
    if (splitFileNames.includes(values.fileName)) {
      fileName = `dbo.Download_${values.splitDownloadFile}.csv`;
    } else {
      fileName = `dbo.Demand_Forecast_${values.fileName}.csv`;
    }
  
    try {
      const response = await axios.post(
        "https://bieno-da09-p-930494-webapi-01.azurewebsites.net/demand_forecast/downloadfile",
        { filename: fileName, tag: "/Falcon/Demand_Forecast" },
        { responseType: "blob" }
      );
  
      // Create a blob URL and initiate download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = `${values.fileName}.csv`;
      document.body.appendChild(link);
      link.click();
  
      // Clean up: remove the link element and revoke the Blob URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      // console.error("Error downloading file:", error);
    } finally {
      setShowLoader(false);
    }
  };
  
  var splitDownloadOptions = [];

  if (values.fileName === "GT_Haircare") {
    downloadData.forEach((word) => {
      word.includes("Hair") && splitDownloadOptions.push(word);
    });
  } else if (values.fileName === "GT_Skin_Non_Winter") {
    downloadData.forEach((word) => {
      word.includes("Skin") && splitDownloadOptions.push(word);
    });
  } else splitDownloadOptions.push();

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <StyledCard>
          <Grid item container spacing={2} justifyContent="flex-end" md={12}>
            {/* <TestComponent/> */}
            <Grid item md={2}>
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-simple-select-label">Decimal</InputLabel>
                <Select
                  value={values.decimal}
                  label="Decimal"
                  onChange={handleChange}
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </StyledCard>
      </Grid>
      <Grid item md={12}>
        {values?.fileName.length !== 0 && values.clickApply === true ? (
          <Section
            title={"Planning Dashboard"}
            onSettings={

              <ExportOption
                checked={checked}
                onClick={
                  checked === "chart" ? () => saveasImage() : () => exportCsv()
                }
              />
            }
            actions={
              <ButtonGroup
                setValue={setChecked}
                value={checked}
                options={values.displayData=== "Agg" ? [
                  { label: "Chart", value: "chart" },
                  { label: "Table", value: "table" },
                ] : [
                  // { label: "Chart", value: "chart" },
                ]
              }
                primaryColor={"#31b2dc"}
                // primaryColor={theme.palette.mode=="dark"?"#4b4b4b":#01CFFE"}
                width={84}
              />
            }
          >
            <Grid container spacing={2}>
              <Grid item md={12}>

                {( 
                values.fileName.startsWith("GT") ||
                  values.fileName.startsWith("OH")
                 ) && (
                  <DisplayButton
                    name={"QTR BM Planning"}
                    active={values.timePeriod === "QTR_BM"}
                    buttonOnclick={() => {
                      setFieldValue("timePeriod", "QTR_BM");
                      values.displayData==="Non-Agg" ? onApplyNonAgg(values.primary,values.secondary) : onApply(values.primary, values.secondary)
                    }}
                  />
                )}
                {(values.fileName.startsWith("GT") ||
                
                  values.fileName.startsWith("OH")) && (
                  <DisplayButton
                    name={"BM Planning"}
                    active={values.timePeriod === "Book_Month"}
                    buttonOnclick={() => {
                      setFieldValue("timePeriod", "Book_Month");
                      values.displayData==="Non-Agg" ? onApplyNonAgg(values.primary,values.secondary) : onApply(values.primary, values.secondary)
                    }}
                    style={{ visibility: "hidden" }}
                  />
                )}
                    {(values.fileName.startsWith("Ecom")) && (
                <DisplayButton
                  name={"BM Planning"}
                  active={values.timePeriod === "BM"}
                  buttonOnclick={() => {
                    setFieldValue("timePeriod", "BM");
                    values.displayData==="Non-Agg" ? onApplyNonAgg(values.primary,values.secondary) : onApply(values.primary, values.secondary)
                  }}
                  style={{ visibility: "hidden" }}
                />
              )}
                {(values.fileName.startsWith("GT") ||
                  values.fileName.startsWith("MT") ||
                  values.fileName.startsWith("OH")  
                  // values.fileName.startsWith("Ecom")
                ) && (
                  <DisplayButton
                    name={"MOC Planning"}
                    active={values.timePeriod === "MOC"}
                    buttonOnclick={() => {
                      setFieldValue("timePeriod", "MOC");
                      values.displayData==="Non-Agg" ? onApplyNonAgg(values.primary,values.secondary) : onApply(values.primary, values.secondary)
                    }}
                  />
                )}
                {(values.fileName.startsWith("GT") ||
                  values.fileName.startsWith("OH")) && (
                  <DisplayButton
                    name={"TDP Planning"}
                    active={values.timePeriod === "TDP"}
                    buttonOnclick={() => {
                      setFieldValue("timePeriod", "TDP");
                      values.displayData==="Non-Agg" ? onApplyNonAgg(values.primary,values.secondary) : onApply(values.primary, values.secondary)
                    }}
                  />
                )}

                <Button
                  size="small"
                  variant="contained"
                  onClick={values.displayData==="Agg" ? () => onApply(values.primary, values.secondary) : () => {
                    onApplyNonAgg(values.primary, values.secondary)
                    setFieldValue("applyNonAggClicked",true)}}
                  // onClick={handleClick}
                  style={{
                    height: "32px",
                    marginBottom: "2px",
                    marginRight: "10px",
                    float: "right",
                  }}
                >
                  Apply
                </Button>
                {splitFileNames.includes(values.fileName) ? (
                  <OptionsButton
                    filename={values.fileName}
                    downloadOptions={splitDownloadOptions}
                    loader={showLoader}
                    onClick={values.splitDownloadFile && downloadFile}
                  />
                ) : (
                  <LoadingButton
                    size="small"
                    variant="contained"
                    onClick={downloadFile}
                    loading={showLoader}
                    style={{
                      height: "32px",
                      marginBottom: "2px",
                      marginRight: "10px",
                      float: "right",
                    }}
                  >
                    Download
                  </LoadingButton>
                )}
                <Grid item xs={4}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={values.displayData}
                  onChange={(event) => setFieldValue("displayData",event.target.value)}
                >
                  <FormControlLabel
                    value="Agg"
                    control={<Radio />}
                    label="Aggregated Data"
                    onClick={() => {
                      onApply(values.primary,values.secondary)
                      setFieldValue("applyNonAggClicked",true)
                      setFieldValue("displayData","Agg")
                    }}
                  />
                 
                  {shouldDisplayFormControl  && (<FormControlLabel
                    value="Non-Agg"
                    control={<Radio />}
                    label="Non Aggregated Data"
                    onClick={() => {
                      onApplyNonAgg(values.primary,values.secondary);setFieldValue("applyNonAggClicked",true)
                      setFieldValue("displayData","Non-Agg")
                    }}
                  />)}
                </RadioGroup>
              </FormControl>
            </Grid>
              </Grid>
              <Grid item md={6} sx={{ mt: 2 }}>
                <Field
                  label="Primary Axis"
                  multiple
                  name="primary"
                  options={options.filter(
                    (item) =>
                      !values.secondary
                        ?.map((i) => i.label)
                        .includes(item.label)
                  )}
                  component={AutocompleteField}
                />
              </Grid>
              <Grid item md={6} sx={{ mt: 2 }}>
                <Field
                  multiple
                  name="secondary"
                  label="Secondary Axis"
                  options={options.filter(
                    (item) =>
                      !values.primary?.map((i) => i.label).includes(item.label)
                  )}
                  component={AutocompleteField}
                />
              </Grid>
            </Grid>

            <Box sx={{ marginTop: 2 }}>
              {demandForecastLoading || demandForecastLoadingNonAgg ? (
                <Loader />
              ) : checked === "chart" ? (
                <ReactECharts
                  style={{ height: "500px", width: "100%" }}
                  option={values.displayData==="Agg"?accuracyOption:accuracyOptionNonAgg}
                  theme={theme.palette.mode}
                  // ref={(e) => {
                  //   const instance = e?.getEchartsInstance();
                  //   setchartInstance(instance);
                  // }}
                />
              ) : (
                <DataGridTable
                  height="400px"
                  onGridReady={(e) => setGridApi(e.api)}
                  suppressRowTransform={true}
                  columnDefs={columnDefs}
                  rowData={values.displayData==="Agg"?data:dataNonAgg}
                  borders={false}
                  defaultColDef={{
                    cellStyle: () => ({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }),
                    wrapHeaderText: true,
                  }}
                />
              )}
            </Box>
            <Box>
            <div>Sales considered till {tdpForInputDate}</div>
            </Box>
          </Section>
        ) : (
          <Section title={"Planning Dashboard"}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ color: theme.palette.text.primary }}
              >
                Please select filename for Demand Forecast
              </Typography>
            </div>
          </Section>
        )}
      </Grid>

      <Grid item md={12}>
        <FeatureImportance />
      </Grid>
      <Grid item md={12}>
        <Features />
      </Grid>
    </Grid>
  );
}
