import React,{useEffect} from "react";
import Section from "components/Section";
import ReactECharts from "echarts-for-react";
import { Grid, Button, Typography, TextField, Skeleton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Autocomplete from "components/Autocomplete";
import useFeatureImportance from "../hooks/useFeatureImportance";
import { useState } from "react";
import useGetFeaturesFilters from "services/DemandForecast/useGetFeatureFilters";
import useGetBPList from "services/DemandForecast/useGetBPList";
import useGetFeatureTables from "services/DemandForecast/useGetFeatureTables";
import useGetFeaturesData from "services/DemandForecast/useGetFeaturesData";
import useGetFeatureMonths from "services/DemandForecast/useGetFeatureMonths";
import { Field, useFormikContext } from "formik";
import MultiSelectDropdown from "components/MultiSelectDropdown";
import MultiSelectDropdownF from "components/MultiSelectDropdownF.js";
import WestIcon from "@mui/icons-material/West";
import Loader from "components/Loader";
import { useTheme } from "@emotion/react";

const MONTHS = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

export default function FeatureImportance(props) {
  const d = new Date();
  const dayOfMonth = d.getDate();
  const currentMonth = d.getMonth();

  const previousMonthName = MONTHS[(currentMonth - 1 + 12) % 12];
  const currentMonthName = MONTHS[currentMonth];

  const month = dayOfMonth <= 9 ? previousMonthName : currentMonthName

  const theme = useTheme()
  const [fileName, setFileName] = useState(null);
  const [showLoader, setShowLoader] = useState();
  const [featureMonth, setFeatureMonth] = useState(null);
  const { featureTables, featureTablesLoading, featureTablesMutate } = useGetFeatureTables();
  const { featureMonths } = useGetFeatureMonths(featureMonth?.value);
  const [count, setCount] = useState(0);
  const { tdpOptions, channelOptions, clusterOptions,anaplanCustomerOptions, optionsLoading } =
    useGetFeaturesFilters((props.isScenarioSimulation) ? (props.details) :(fileName?.value), (props.isScenarioSimulation) ? (month) : (featureMonth?.value));
  const { bpOptions, bpMutate, bpOptionsLoading } = useGetBPList();
  const { featuresData, featuresDataMutate} = useGetFeaturesData();
  const { featureOptions, mutate, isLoading,featureImportance } = useFeatureImportance();
  const { values, setFieldValue } = useFormikContext();
  const [displayBackArrow, setBackArrow] = useState(false);
  // console.log("featureOptio",featureOptions)
  const displayAnaplanCustomer =
    fileName && typeof fileName.value === 'string' && fileName.value.startsWith('MT');

    // console.log("")

const preClusterOptions = []
const preBPOptions = []

props?.keys?.forEach((ele)=>{
  return(
    preBPOptions.push(ele.split("_")[0]) &&
    preClusterOptions.push(ele.split("_")[1])
  )
})

const removeDuplicates = (data) => {
  return [...new Set(data)]
}

const newPreClusterOptions = removeDuplicates(preClusterOptions)
const newPreBPOptions = removeDuplicates(preBPOptions)


    useEffect(() => {
      if (
        featureMonth?.value 
      ) {
        featureTablesMutate({
          month: featureMonth?.value
        });
      }
  }, [featureMonth?.value]);
  
  useEffect(() => {
      if (
        !props?.isScenarioSimulation &&
        fileName?.value &&
        values.feature_importance_filters.cluster_name.length !== 0
      ) {
        bpMutate({
          table_name: fileName?.value,
          cluster: values.feature_importance_filters.cluster_name,
          month:featureMonth?.value
        });
      }
  }, [fileName?.value, values.feature_importance_filters.cluster_name]);

  useEffect(()=>{
    if(props?.isScenarioSimulation){
      setFieldValue("feature_importance_filters.cluster_name",newPreClusterOptions)
      setFieldValue("feature_importance_filters.anaplanCustomer",[props?.featureDetails.anaplanCustomer])
      setFieldValue("feature_importance_filters.basepackDescription",newPreBPOptions)
    }
  },[])

  const onApply = () => {
    setCount(0);
    setBackArrow(false);
    mutate({
      month:featureMonth?.value,
      table_name: fileName?.value,
      channel: values.feature_importance_filters.channel,
      cluster_name: values.feature_importance_filters.cluster_name,
      tdp: values.feature_importance_filters.tdp,
      basepackDescription: values.feature_importance_filters.basepackDescription,
      Anaplan_Customer:values.feature_importance_filters.anaplanCustomer,
      variable_type: values.variable_type,
      variable: "",
    });
    featuresDataMutate({
      month:featureMonth?.value,
      table_name: fileName?.value,
      basepackDescription: values.feature_importance_filters.basepackDescription
    });
  };
  function handleClick(params) {
    setBackArrow(true);
    setCount(count + 1);

    if (count === 0) {
      values.firstClickVariable = params.name;
      mutate({
        month:featureMonth?.value,
        table_name: fileName?.value,
        channel: values.feature_importance_filters.channel,
        cluster_name: values.feature_importance_filters.cluster_name,
        tdp: values.feature_importance_filters.tdp,
        basepackDescription: values.feature_importance_filters.basepackDescription,
        Anaplan_Customer:values.feature_importance_filters.anaplanCustomer,
        variable_type: "Sub Feature Importance Description",
        variable: params.name,
      });
    } else if (count === 1) {
      values.secondClickVariable = params.name;
      mutate({
        month:featureMonth?.value,
        table_name: fileName?.value,
        channel: values.feature_importance_filters.channel,
        cluster_name: values.feature_importance_filters.cluster_name,
        tdp: values.feature_importance_filters.tdp,
        basepackDescription: values.feature_importance_filters.basepackDescription,
        Anaplan_Customer:values.feature_importance_filters.anaplanCustomer,
        variable_type: "Variables",
        variable: params.name,
      });
    } else if (count >= 2) {
      // alert("cant be drilled down further");
    }
  }
  const handleArrowClick = () => {
    if (count === 1) {
      mutate({
        month:featureMonth?.value,
        table_name: fileName?.value,
        channel: values.feature_importance_filters.channel,
        cluster_name: values.feature_importance_filters.cluster_name,
        tdp: values.feature_importance_filters.tdp,
        basepackDescription: values.feature_importance_filters.basepackDescription,
        variable_type: "Final Feature Importance Description",
        Anaplan_Customer:values.feature_importance_filters.anaplanCustomer,
        variable: "",
      });
      setCount(0);
      setBackArrow(false);
    } else if (count >= 2) {
      mutate({
        month:featureMonth?.value,
        table_name: fileName?.value,
        channel: values.feature_importance_filters.channel,
        cluster_name: values.feature_importance_filters.cluster_name,
        tdp: values.feature_importance_filters.tdp,
        basepackDescription: values.feature_importance_filters.basepackDescription,
        variable_type: "Sub Feature Importance Description",
        Anaplan_Customer:values.feature_importance_filters.anaplanCustomer,
        variable: values.firstClickVariable,
      });
      setCount(1);
    }
  };

  useEffect(()=>{
    if(props?.isScenarioSimulation){
      setFeatureMonth({label:month, value:month})
      setFileName({label:props?.details, value:props?.details})
      setFieldValue("feature_importance_filters.channel",[props?.details?.split('_')[0]])
    }
  },[])

  const convertToCsv = (data) => {
    const csvRows = [];
    const headers = Object.keys(data[0]);

    csvRows.push(headers.join(','));

    data.forEach((row) => {
      const values = headers.map((header) => row[header]);
      csvRows.push(values.join(','));
    });

    return csvRows.join('\n');
  };

  const downloadCSV = () => {
    setShowLoader(true);
    const csvContent = convertToCsv(featuresData);
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "result.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
    setShowLoader(false);
  }
  
  return (
    <React.Fragment>
      <Section title={"Feature Importance"}>
      <Grid flex={1} justifyContent={"flex-start"} container spacing={2}>
        <Grid item md={2}>
            <Autocomplete
              label="Month"
              value={featureMonth}
              options={featureMonths}
              onChange={(e, value) => {
                setFieldValue("feature_importance_filters.channel", []);
                setFieldValue("feature_importance_filters.tdp", []);
                setFieldValue("feature_importance_filters.cluster_name", []);
                setFieldValue("feature_importance_filters.anaplanCustomer", []);
                setFieldValue("feature_importance_filters.basepackDescription", []);
                setFeatureMonth(value);
                setFileName("");
              }}
              disabled={props?.isScenarioSimulation}
            />
          </Grid>
          <Grid item md={2}>
            {!featureTablesLoading?
            <Autocomplete
              label="File Name"
              value={fileName}
              onChange={(e, value) => {
                setFieldValue("feature_importance_filters.channel", []);
                setFieldValue("feature_importance_filters.tdp", []);
                setFieldValue("feature_importance_filters.cluster_name", []);
                setFieldValue("feature_importance_filters.anaplanCustomer", []);
                setFieldValue("feature_importance_filters.basepackDescription", []);
                setFileName(value);
              }}
              options={featureTables}
              displayDropdown={featureTablesLoading}
              disabled={props?.isScenarioSimulation}
            />:<Skeleton height={50}/>}
          </Grid>

          <Grid item md={2}>
            {!optionsLoading && !featureTablesLoading?
              <Field
              label="Channel"
              multiple
              name="feature_importance_filters.channel"
              options={channelOptions}
              noBorders={false}
              variant="outlined"
              fullWidth
              component={MultiSelectDropdownF}
              displayDropdown={optionsLoading}
              disabled={props?.isScenarioSimulation}
            />:<Skeleton height={50}/>}
          </Grid>
          {displayAnaplanCustomer ? (
            <React.Fragment>
          <Grid item md={2}>
          {!optionsLoading && !featureTablesLoading?
            <Field
              label="Anaplan Customer"
              multiple
              fullWidth
              name="feature_importance_filters.anaplanCustomer"
              options={anaplanCustomerOptions}
              noBorders={false}
              variant="outlined"
              component={MultiSelectDropdownF}
              displayDropdown={optionsLoading}
              disabled={props?.isScenarioSimulation}
            />:<Skeleton height={50}/>}
          
          </Grid>
         
          <Grid item md={3}>
            {!optionsLoading && !featureTablesLoading?
            <Field
              label="Cluster"
              multiple
              fullWidth
              name="feature_importance_filters.cluster_name"
              options={clusterOptions}
              noBorders={false}
              variant="outlined"
              component={MultiSelectDropdownF}
              displayDropdown={optionsLoading}
              disabled={props?.isScenarioSimulation}
            />:<Skeleton height={50}/>}
          </Grid>
         
          <Grid item md={2}>
          {!optionsLoading && !featureTablesLoading?
            <Field
              label="TDP"
              multiple
              fullWidth
              name="feature_importance_filters.tdp"
              options={tdpOptions}
              noBorders={false}
              variant="outlined"
              component={MultiSelectDropdownF}
              displayDropdown={optionsLoading}
            />:<Skeleton height={50}/>}
          </Grid>
          </React.Fragment>
          ):( <React.Fragment>
           <Grid item md={3}>
           {!optionsLoading && !featureTablesLoading?
            <Field
              label="Cluster"
              multiple
              fullWidth
              name="feature_importance_filters.cluster_name"
              options={clusterOptions}
              noBorders={false}
              variant="outlined"
              component={MultiSelectDropdownF}
              displayDropdown={optionsLoading}
              disabled={props?.isScenarioSimulation}
            />:<Skeleton height={50}/>}
          </Grid>
         
          <Grid item md={2}>
          {!optionsLoading && !featureTablesLoading?
            <Field
              label="TDP"
              multiple
              fullWidth
              name="feature_importance_filters.tdp"
              options={tdpOptions}
              noBorders={false}
              variant="outlined"
              component={MultiSelectDropdownF}
              displayDropdown={optionsLoading}
            />:<Skeleton height={50}/>}
          </Grid>
          </React.Fragment>)}
          </Grid>
          <Grid container flex={1} mt={1}>
      <Grid item md={4}>
        {!bpOptionsLoading && !optionsLoading && !featureTablesLoading? 
            <Field
              label="Basepack Description"
              multiple
              fullWidth
              name="feature_importance_filters.basepackDescription"
              options={bpOptions}
              noBorders={false}
              variant="outlined"
              component={MultiSelectDropdownF}
              displayDropdown={bpOptionsLoading}
              disabled={props?.isScenarioSimulation}
            /> : <Skeleton height={50}/> }
       </Grid>
      
      <Grid item md={3} ml={2}>
            <Button
              variant="contained"
              onClick={onApply}
              disabled={
                !fileName?.value ||
                values.feature_importance_filters.tdp.length === 0 ||
                (values.feature_importance_filters.channel.length === 0) |
                  (values.feature_importance_filters.cluster_name.length ===
                    0) ||
                values.feature_importance_filters.basepackDescription.length === 0
              }
            >
              Apply
            </Button>
            <LoadingButton
                    variant="contained"
                    onClick={downloadCSV}
                    loading={showLoader}
                    disabled={
                      !fileName?.value ||
                      // values.feature_importance_filters.tdp.length === 0 ||
                      (values.feature_importance_filters.channel.length === 0) |
                        (values.feature_importance_filters.cluster_name.length ===
                          0) ||
                      values.feature_importance_filters.basepackDescription.length === 0 || featuresData.length === 0
                    }
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    Download
                  </LoadingButton> 
           </Grid>
        </Grid>

        {fileName?.value &&
        // values.feature_importance_filters.tdp.length !== 0 &&
        values.feature_importance_filters.channel.length !== 0 &&
        values.feature_importance_filters.cluster_name.length !== 0 &&
        values.feature_importance_filters.basepackDescription.length !== 0 ? (
          <React.Fragment>
            {displayBackArrow && (
              <Button
                onClick={handleArrowClick}
                sx={{ mt: 2, fontSize: "98px" }}
              >
                <WestIcon />
              </Button>
            )}
            {isLoading ? (
              <Loader />
            ) : (
              <React.Fragment>
                {featureImportance.length !== 0 ?( 
              <ReactECharts
                style={{ height: "400px", width: "100%" }}
                option={featureOptions}
                onEvents={{ click: handleClick }}
                // theme={theme.palette.mode}
              />
              ):(<div style={{ display: "flex", justifyContent: "center", margin: 3 }}>
              <Typography variant="body2" gutterBottom sx={{ mt: 2 }}><b>No Data Available</b></Typography>
          </div>)}
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <div style={{ display: "flex", justifyContent: "center", margin: 3 }}>
            <Typography variant="body2" gutterBottom sx={{ mt: 2, color:theme.palette.text.primary }}>
              Please select <b>Filename</b>,<b>Channel</b>,<b>TDP</b>,
              <b>Cluster</b>,<b>BasePack</b> for feature importance
            </Typography>
          </div>
        )}
      </Section>
    </React.Fragment>
  );
}
