import { useQuery } from "react-query";
import { postApi } from "services/apiCalls";
import useNotify from "utils/useNotify";
import { useEffect } from "react";

export default function useGetFeaturesFilters(fileName,month) {
  const path = "demand_forecast/get_key_filters";

  const { data, isLoading, error, isError } = useQuery(
    ["getFilters", fileName],
    () => postApi(path, { table_name: fileName, month:month }),
    { enabled: !!fileName }
  );
  const { showError } = useNotify();

  useEffect(() => {
    if (isError) {
      showError(JSON.stringify(error?.response?.data));
    }
  }, [error?.response?.data, isError, showError]);

  const {
    BP_description = [],
    TDP = [],
    Cluster_Name = [],
    Channel = [],
    Anaplan_Customer = [],
  } = data ?? [];

  return {
    channelOptions: Channel,
    tdpOptions: TDP,
    clusterOptions: Cluster_Name,
    basepackOptions: BP_description,
    anaplanCustomerOptions: Anaplan_Customer,
    optionsLoading: isLoading,
  };
}
